import React, { useEffect, useState } from "react";
import { toCurrency, toNaira } from "../../assets/js/utils/functions";
import useCopyClipboard from "../hooks/useCopyClipboard";
import { useModals } from "../hooks/useModals";
import useSlider from "../hooks/useSlider";
import useSteps from "../hooks/useSteps";
import { AppBtn } from "../ui/buttons";
import CircularProgress from "../ui/circular-progress";
import ConfirmationModal from "./confirmation-modal";

interface IProps {
  goBack: () => void;
  paymentData: {
    amount: number;
    bank: {
      account_name: string;
      account_number: string;
      bank_name: string;
    };
  };
  triggerError: VoidFunction;
}

enum STEPS {
  "ACCOUNT" = "ACCOUNT",
  "WAITING" = "WAITING",
}

const BankTransfers: React.FC<IProps> = ({ goBack, paymentData, triggerError }) => {
  const { modals, toggleModal } = useModals(["cancel_transfer"]);
  const {
    amount,
    bank: { account_name, account_number, bank_name },
  } = paymentData;
  const [copied, copyAccount] = useCopyClipboard(account_number, { successDuration: 500 });
  const [progress, setProgress] = useState(0);
  const [timeWaited, setTimeWaited] = useState(0);

  const { changeStep, step, stepIndex } = useSteps([STEPS.ACCOUNT, STEPS.WAITING], 0);
  const { next, slider, slide, previous, slides, currentSlide, switchSlides } = useSlider({
    slides: 2,
    sameWidthAsSlider: false,
    gap: 0,
  });

  const accountValidityTime = 25 * 60 * 1000;

  useEffect(() => {
    switchSlides(stepIndex);
  }, [stepIndex]);

  useEffect(() => {
    let timeWaited = 0;

    const interval = setInterval(() => {
      if (timeWaited < accountValidityTime) {
        timeWaited += 1000;
        setTimeWaited(timeWaited);
        setProgress(timeWaited / accountValidityTime);
      } else {
        clearInterval(interval);
        triggerError();
      }
    }, 1000);
  }, []);

  const toggle = () => {
    // if(step === STEPS.ACCOUNT){
    changeStep(step === STEPS.ACCOUNT ? STEPS.WAITING : STEPS.ACCOUNT);
    // }
  };
  return (
    <div className="">
      <div className="">
        <div className="relative flex flex-col items-center border-b border-grey-border border-opacity-50 mb-5 xl:mb-7.5">
          <button
            className="absolute top-0 left-0 hidden lg:flex items-center justify-center w-12.5 h-12.5 rounded-10 text-primary-500 bg-grey-fields-200"
            onClick={() => toggleModal("cancel_transfer")}
          >
            {/* prettier-ignore */}
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M13 7H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <figure className="flex items-center justify-center w-15 h-15 bg-accent-red-500 rounded-full my-3.75">
            {/* prettier-ignore */}
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
              <path d="M27.3998 20.9375C26.9873 24.2625 24.2623 26.9875 20.9373 27.4C18.9248 27.65 17.0498 27.1 15.5873 26.025C14.7498 25.4125 14.9498 24.1125 15.9498 23.8125C19.7123 22.675 22.6748 19.7 23.8248 15.9375C24.1248 14.95 25.4248 14.75 26.0373 15.575C27.0998 17.05 27.6498 18.925 27.3998 20.9375Z" fill="white" />
              <path d="M12.4875 2.5C6.975 2.5 2.5 6.975 2.5 12.4875C2.5 18 6.975 22.475 12.4875 22.475C18 22.475 22.475 18 22.475 12.4875C22.4625 6.975 18 2.5 12.4875 2.5ZM11.3125 11.0875L14.325 12.1375C15.4125 12.525 15.9375 13.2875 15.9375 14.4625C15.9375 15.8125 14.8625 16.925 13.55 16.925H13.4375V16.9875C13.4375 17.5 13.0125 17.925 12.5 17.925C11.9875 17.925 11.5625 17.5 11.5625 16.9875V16.9125C10.175 16.85 9.0625 15.6875 9.0625 14.2375C9.0625 13.725 9.4875 13.3 10 13.3C10.5125 13.3 10.9375 13.725 10.9375 14.2375C10.9375 14.6875 11.2625 15.05 11.6625 15.05H13.5375C13.825 15.05 14.05 14.7875 14.05 14.4625C14.05 14.025 13.975 14 13.6875 13.9L10.675 12.85C9.6 12.475 9.0625 11.7125 9.0625 10.525C9.0625 9.175 10.1375 8.0625 11.45 8.0625H11.5625V8.0125C11.5625 7.5 11.9875 7.075 12.5 7.075C13.0125 7.075 13.4375 7.5 13.4375 8.0125V8.0875C14.825 8.15 15.9375 9.3125 15.9375 10.7625C15.9375 11.275 15.5125 11.7 15 11.7C14.4875 11.7 14.0625 11.275 14.0625 10.7625C14.0625 10.3125 13.7375 9.95 13.3375 9.95H11.4625C11.175 9.95 10.95 10.2125 10.95 10.5375C10.9375 10.9625 11.0125 10.9875 11.3125 11.0875Z" fill="white" />
            </svg>
          </figure>
          <h1 className="flex flex-col items-center justify-center text-black text-2lg xl:text-2xl font-medium mb-3.75 xl:mb-5 !leading-snug">
            <span className="block">Transfer</span>
            <span className="font-bold">{toCurrency(toNaira(amount))}</span>
          </h1>
        </div>
        <div className="bg-grey-fields-200 rounded-10 pt-6.25 pb-4 mb-3.75">
          <ul className="grid w-full grid-cols-[100%,100%] overflow-hidden" ref={slider}>
            <div className="flex flex-col items-center flex-1" ref={slide}>
              <span className="bg-white text-xs font-semibold rounded-5 px-2.5 py-1.5 mb-2.5 text-primary-500">
                {bank_name}
              </span>
              <div className="flex items-center mb-1.25">
                <h1 className="text-2lg font-bold">{account_number}</h1>
                <button
                  className="flex items-center justify-center text-primary-500 bg-primary-500 bg-opacity-5 rounded-5 p-1.25 ml-1.25"
                  onClick={() => copyAccount()}
                >
                  {/* prettier-ignore */}
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
                    <path d="M6.47533 13.7709H4.02533C1.74449 13.7709 0.729492 12.7559 0.729492 10.475V8.02502C0.729492 5.74419 1.74449 4.72919 4.02533 4.72919H6.47533C8.75616 4.72919 9.77116 5.74419 9.77116 8.02502V10.475C9.77116 12.7559 8.75616 13.7709 6.47533 13.7709ZM4.02533 5.60419C2.21699 5.60419 1.60449 6.21669 1.60449 8.02502V10.475C1.60449 12.2834 2.21699 12.8959 4.02533 12.8959H6.47533C8.28366 12.8959 8.89616 12.2834 8.89616 10.475V8.02502C8.89616 6.21669 8.28366 5.60419 6.47533 5.60419H4.02533Z" fill="currentColor" />
                    <path d="M9.97533 10.2709H9.33366C9.09449 10.2709 8.89616 10.0725 8.89616 9.83335V8.02502C8.89616 6.21669 8.28366 5.60419 6.47533 5.60419H4.66699C4.42783 5.60419 4.22949 5.40585 4.22949 5.16669V4.52502C4.22949 2.24419 5.24449 1.22919 7.52533 1.22919H9.97533C12.2562 1.22919 13.2712 2.24419 13.2712 4.52502V6.97502C13.2712 9.25585 12.2562 10.2709 9.97533 10.2709ZM9.77116 9.39585H9.97533C11.7837 9.39585 12.3962 8.78335 12.3962 6.97502V4.52502C12.3962 2.71669 11.7837 2.10419 9.97533 2.10419H7.52533C5.71699 2.10419 5.10449 2.71669 5.10449 4.52502V4.72919H6.47533C8.75616 4.72919 9.77116 5.74419 9.77116 8.02502V9.39585Z" fill="currentColor" />
                  </svg>
                </button>
              </div>
              <span className="text-sm">{account_name}</span>
              <div className="flex mt-5">
                <figure className="flex items-center justify-center w-7.5 h-7.5 rounded-10 bg-white text-accent-green-500">
                  <CircularProgress progress={progress} width={20} outline={3} />
                </figure>
                <div className="flex flex-col justify-center text-xxs ml-1.25 text-dark">
                  <span className="block leading-snug">This account expires in</span>
                  <span className="block font-semibold leading-snug">
                    {formatTime(accountValidityTime - timeWaited)}
                  </span>
                </div>
              </div>
              {step === STEPS.ACCOUNT && (
                <div className="w-full border-t border-grey-border border-opacity-50 mt-5 pt-3.5 px-3 text-center">
                  <span className="text-xs text-accent-red-500 font-medium flex items-center justify-center">
                    {/* prettier-ignore */}
                    <svg width="16" viewBox="0 0 24 24" fill="none" className="mr-1">
                      <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12 8V13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M11.9945 16H12.0035" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    Please transfer exactly&nbsp; <b>{toCurrency(toNaira(amount))}</b>
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center flex-1">
              <span className="bg-white text-xs font-semibold rounded-5 px-2.5 py-1.5 text-primary-500">
                Waiting for Transfer
              </span>
              <span className="inline-block text-dark text-xs text-center mt-1.5 mb-2.5 max-w-[220px]">
                We&apos;re waiting to receive your transfer. This can take some minutes
              </span>
              <div className="mt-2.5 bg-white h-1.5 w-full max-w-[200px] rounded-10 overflow-hidden">
                <div className="bg-accent-green-500 h-full rounded-10 w-1/4 transfer-waiting-loader"></div>
              </div>

              <div className="flex mt-5">
                <figure className="flex items-center justify-center w-7.5 h-7.5 rounded-10 bg-white text-accent-green-500">
                  <CircularProgress progress={progress} width={20} outline={3} />
                </figure>
                <div className="flex flex-col justify-center text-xxs ml-1.25 text-dark">
                  <span className="block leading-snug">This account expires in</span>
                  <span className="block font-semibold leading-snug">
                    {formatTime(accountValidityTime - timeWaited)}
                  </span>
                </div>
              </div>
            </div>
          </ul>
        </div>
        <AppBtn isBlock className="hidden lg:block" size="lg" onClick={toggle}>
          {step === STEPS.ACCOUNT ? "I have made the transfer" : "Show account details"}
        </AppBtn>
      </div>
      <div className="lg:hidden border-t border-grey-border border-opacity-50 py-3.75 lg:py-0 lg:border-none flex flex-col space-y-2.5">
        <div className="w-full">
          <AppBtn
            isBlock
            size="lg"
            className="flex-1 mr-2.5"
            color="neutral"
            onClick={() => toggleModal("cancel_transfer")}
          >
            Cancel Transfer
          </AppBtn>
        </div>
        <div className="w-full">
          <AppBtn isBlock size="lg" className="flex-1" onClick={toggle}>
            {step === STEPS.ACCOUNT ? "I have made the transfer" : "Show account details"}
          </AppBtn>
        </div>
      </div>
      <ConfirmationModal
        onConfirm={goBack}
        show={modals.cancel_transfer}
        toggle={() => toggleModal("cancel_transfer")}
      />
    </div>
  );
};

function formatTime(time: number) {
  const timeInSecs = time / 1000;
  const mins = Math.floor(timeInSecs / 60);
  const secs = Math.floor(timeInSecs % 60);

  return `${mins} mins ${secs} secs`;
}

export default BankTransfers;
